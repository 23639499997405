import { useState, useEffect } from 'react'

import { checkStorageSupport, windowIsAvailable } from '../helpers'

const LOCAL_EVENT_LISTENER_NAME = 'localStorageChange'
const SESSION_EVENT_LISTENER_NAME = 'sessionsStorageChange'

const getEventKey = (isSessionStorage: boolean) =>
  isSessionStorage ? SESSION_EVENT_LISTENER_NAME : LOCAL_EVENT_LISTENER_NAME

export const createAndDispatchEvent = (
  key: string,
  payload: string | null,
  isSessionStorage = false,
) => {
  if (windowIsAvailable()) {
    const event = new CustomEvent(`${getEventKey(isSessionStorage)}#${key}`, {
      detail: payload,
    } as CustomEventInit)

    window.dispatchEvent(event)
  }
}

const isSupported = (isSessionStorage: boolean): boolean => {
  return (
    windowIsAvailable() &&
    checkStorageSupport(isSessionStorage ? 'sessionStorage' : 'localStorage')
  )
}

export const useStorage = (key: string, isSessionStorage = false) => {
  const [value, setValue] = useState<string | null>(null)

  useEffect(() => {
    if (isSupported(isSessionStorage)) {
      const handleLocalStorageChange = (event: CustomEvent) => {
        setValue(event.detail)
      }
      const registerLocalStorageChange = () => {
        if (windowIsAvailable()) {
          window.addEventListener(
            `${getEventKey(isSessionStorage)}#${key}`,
            handleLocalStorageChange as EventListener,
          )
        }
      }

      registerLocalStorageChange()

      const item = isSessionStorage
        ? sessionStorage.getItem(key)
        : localStorage.getItem(key)

      if (item) {
        setValue(item)
      }
      return () =>
        window.removeEventListener(
          `${getEventKey(isSessionStorage)}#${key}`,
          handleLocalStorageChange as EventListener,
        )
    }

    return () => {
      // This is intentional
    }
  }, [])

  const setStorageValue = (newValue: string) => {
    if (isSessionStorage) {
      sessionStorage.setItem(key, newValue)
    } else {
      localStorage.setItem(key, newValue)
    }

    createAndDispatchEvent(key, newValue, isSessionStorage)
  }

  const removeStorageValue = () => {
    if (isSessionStorage) {
      sessionStorage.removeItem(key)
    } else {
      localStorage.removeItem(key)
    }

    createAndDispatchEvent(key, null, isSessionStorage)
  }

  return {
    storageValue: value,
    setStorageValue,
    removeStorageValue,
    isStorageSupported: isSupported(isSessionStorage),
  }
}

export default useStorage
