import { Image, ImageDimension } from 'frontend/types'

const getWidth = (dimension: ImageDimension) => dimension.split('x')[0]

const createSourceSetFromImageList = (image: Image | null) => {
  if (!image) return undefined

  const enabledDimensions: ImageDimension[] = [
    '1280x720',
    '960x540',
    '640x360',
    '320x180',
    '240x135',
  ]

  const srcSet = enabledDimensions.reduce(
    (acc: string[], dimension: ImageDimension) => {
      if (image[dimension])
        return acc.concat(`${image[dimension]} ${getWidth(dimension)}w`)

      return acc
    },
    [],
  )
  return srcSet.join(', ')
}

const PLACEHOLDER_IMAGE_FOLDER = '/images/1/'

export const placeholderImage = {
  '240x135': `${PLACEHOLDER_IMAGE_FOLDER}240x135.png`,
  '320x180': `${PLACEHOLDER_IMAGE_FOLDER}320x180.png`,
  '640x360': `${PLACEHOLDER_IMAGE_FOLDER}640x360.png`,
  '960x540': `${PLACEHOLDER_IMAGE_FOLDER}960x540.png`,
  '1280x720': `${PLACEHOLDER_IMAGE_FOLDER}1280x720.png`,
}

export default createSourceSetFromImageList
