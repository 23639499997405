import { Announcement } from 'backend/services/v1/edithService.types'
import { SearchParams } from 'backend/services/v1/urApiService.types'
import {
  QuickSearchResponse,
  RecommendationResult,
  SearchResult,
} from 'frontend/types'
import { windowIsAvailable } from 'frontend/utils/helpers'
import { urlEncodeParams } from 'frontend/utils/urlHelpers'
import queryString from 'query-string'

const handleErrors = (response: Response, url: string) => {
  if (!response.ok) {
    const errorMessage = `${response.statusText}, destination url: ${url}`
    throw Error(errorMessage)
  }
  return response
}

const getAbortSignal = (): [RequestInit, () => void] => {
  if (windowIsAvailable() && window.AbortController) {
    const controller = new AbortController()
    const { signal } = controller
    const handlePageUnload = () => {
      //  Causes fetch to throw AbortError
      controller.abort()
    }
    window.addEventListener('beforeunload', handlePageUnload)

    return [
      { signal },
      () => {
        window.removeEventListener('beforeunload', handlePageUnload)
      },
    ]
  }

  return [{} as RequestInit, () => {}]
}

const getRequest = async <T>(url: string): Promise<T> => {
  try {
    const [requestInit, destroySignal] = getAbortSignal()
    const response = await fetch(url, { ...requestInit })
    destroySignal()
    handleErrors(response, url)
    return await response.json()
  } catch (error) {
    if ((error as Error).name === 'AbortError') {
      //  To prevent downstream components from crashing, a hanging promise
      //  is returned. In this case this does not cause the component to hang
      //  since this is done on page leave aka beforeunload.
      return new Promise(() => {})
    }

    throw error
  }
}

export const getSuggestions = async (query: string) => {
  const url = `/api/v1/quick_search?${urlEncodeParams({ query })}`
  return getRequest<QuickSearchResponse>(url)
}

export const getSearchResults = async (params: SearchParams) => {
  const url = `/api/v1/search?${queryString.stringify(params, {
    arrayFormat: 'bracket',
  })}`

  return getRequest<SearchResult>(url)
}

export const getAnnouncement = async () => {
  const url = '/api/v1/announcements'
  const response = await fetch(url, {
    headers: {
      'Cache-Control': 'no-cache',
    },
  })

  handleErrors(response, url)

  return response.json() as Promise<Announcement>
}

export const getUserRecommendations = (userId: string, endpoint: string) => {
  return getRequest<RecommendationResult>(
    `/api/v1/recommendations?userId=${userId}&endpoint=${endpoint}`,
  )
}
