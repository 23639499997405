import classNames from 'classnames'
import Image from 'next/image'
import { useEffect, useState } from 'react'
import styles from './NextImage.module.css'
import { Image as ImageInterface } from 'frontend/types'
import { ResponsiveImageType } from 'frontend/enums'
import ResponsiveImage from 'components/ResponsiveImage/ResponsiveImage'

interface ImageProps {
  priority?: boolean
  alt?: string
  src: string
  width: number
  height: number
  className?: string
  onClick?: () => void
  fallbackSrc?: string
  roundedCorners?: boolean
  shadow?: boolean
  sizes?: string
  oldResponsiveImage?: {
    forceLowRes?: boolean
    sourceImage: ImageInterface
    type: ResponsiveImageType
  }
}

const NextImage = ({
  height,
  src,
  width,
  alt,
  priority,
  onClick,
  className,
  fallbackSrc,
  roundedCorners,
  shadow,
  oldResponsiveImage,
  sizes = '(min-width: 1600px) calc(1216px * .24), (min-width: 1201px) calc((100vw - 16vw) * .24), (min-width: 768px) 24vw, (min-width: 640px) 48vw, 38vw',
}: ImageProps) => {
  const [imageSrc, setImageSrc] = useState(src)

  useEffect(() => {
    setImageSrc(src)
  }, [src])

  if (oldResponsiveImage) {
    return <ResponsiveImage {...oldResponsiveImage} />
  }

  return (
    <Image
      src={imageSrc}
      height={height}
      width={width}
      alt={alt || ''}
      sizes={sizes}
      className={classNames(
        className,
        { [`${styles.rounded}`]: roundedCorners },
        {
          [`${styles.shadows}`]: shadow,
        },
      )}
      priority={priority}
      onClick={onClick}
      onError={() => {
        if (fallbackSrc) {
          setImageSrc(fallbackSrc)
        }
      }}
    />
  )
}

export default NextImage
