import React from 'react'
import classnames from 'classnames'
import omit from 'lodash/omit'
import { Image } from 'frontend/types'
import { ResponsiveImageType } from 'frontend/enums'
import createSourceSetFromImageList, {
  placeholderImage,
} from './ResponsiveImageUtils'
import styles from './ResponsiveImage.module.css'

type ResponsiveImageProps = {
  forceLowRes?: boolean
  sizes?: string
  sourceImage: Image
  type?: ResponsiveImageType
  imageProps?: React.Attributes
  className?: string
}

const ResponsiveImage = ({
  forceLowRes = false,
  sizes = '(min-width: 1600px) calc(1216px * .24), (min-width: 1201px) calc((100vw - 16vw) * .24), (min-width: 768px) 24vw, (min-width: 640px) 48vw, 38vw',
  sourceImage,
  type,
  imageProps,
  className,
}: ResponsiveImageProps) => {
  const image = sourceImage || placeholderImage

  const classes = classnames(className, styles.image, {
    [`${styles.card}`]: type === ResponsiveImageType.CARD,
    [`${styles.fmg}`]: type === ResponsiveImageType.FMG,
    [`${styles.single}`]: type === ResponsiveImageType.SINGLE,
  })
  return forceLowRes ? (
    <img
      alt=""
      aria-hidden="true"
      width="64"
      height="36"
      className={classes}
      src={image['64x36']}
      {...omit(imageProps, ['src'])}
    />
  ) : (
    <img
      alt=""
      aria-hidden="true"
      width="240"
      height="135"
      className={classes}
      sizes={sizes}
      srcSet={createSourceSetFromImageList(image)}
      src={image['240x135']}
      {...imageProps}
    />
  )
}

export default ResponsiveImage
