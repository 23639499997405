import { useEffect } from 'react'

const useKeys = (
  isEnabled: boolean,
  handlers: Record<string, (e: KeyboardEvent) => void>,
  shouldPreventDefault = true,
) => {
  const keyShouldBeHandled = (e: KeyboardEvent) => {
    return handlers[e.code] !== undefined
  }

  const handleKeyPress = (e: KeyboardEvent) => {
    if (isEnabled && keyShouldBeHandled(e)) {
      if (shouldPreventDefault) {
        e.preventDefault()
      }

      handlers[e.code](e)
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress)
    return () => {
      window.removeEventListener('keydown', handleKeyPress)
    }
  }, [isEnabled])
}

export default useKeys
