import { useState } from 'react'

export const useErrors = () => {
  const [errors, setErrors] = useState<string[]>([])

  const addError = (error: string) => {
    if (!errors.includes(error)) {
      setErrors([error, ...errors])
    }
  }

  const removeError = (error: string) => {
    const newErrors = errors.filter(e => e !== error)
    setErrors(newErrors)
  }

  const resetErrors = () => {
    setErrors([])
  }

  return { addError, errors, removeError, resetErrors }
}

export default useErrors
