import React from 'react'
import Link from 'next/link'

interface SpaLinkProps {
  href: string
  children: React.ReactNode
  [key: string]: unknown
}

const SpaLink = ({ href, children, ...rest }: SpaLinkProps) => {
  return (
    <Link href={href} {...rest}>
      {children}
    </Link>
  )
}

export default SpaLink
