import { useErrors } from 'frontend/utils/hooks/useErrors'
import { useStorage } from 'frontend/utils/hooks/useStorage'
import React, { useEffect, useState } from 'react'
import styles from './ParentalLock.module.css'

export const PARENTAL_LOCK_LOCAL_STORAGE_KEY = 'parental-lock-code'
const WRONG_CODE = 'Du har skrivit in fel kod - försök igen.'
const WRONG_FORMAT = 'Ange en kod som är fyra siffror lång.'
const NO_LOCAL_STORAGE =
  'Barnlåset kräver att kakor och lokal data är aktiverat.'

const ParentalLock = () => {
  const [isActive, setIsActive] = useState(false)
  const [inputCode, setInputCode] = useState('')
  const { addError, errors, removeError, resetErrors } = useErrors()

  const {
    storageValue,
    isStorageSupported,
    setStorageValue,
    removeStorageValue,
  } = useStorage(PARENTAL_LOCK_LOCAL_STORAGE_KEY)

  useEffect(() => {
    setIsActive(Boolean(storageValue))
  }, [storageValue])

  useEffect(() => {
    if (!isStorageSupported) {
      addError(NO_LOCAL_STORAGE)
    }
  }, [])

  const resetForm = () => {
    resetErrors()
    setInputCode('')
  }

  const handleSubmit = (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    event?.preventDefault()

    if (inputCode.match(/[^0-9]/g) || inputCode.length !== 4) {
      return addError(WRONG_FORMAT)
    }

    if (isActive) {
      if (inputCode === storageValue) {
        removeStorageValue()
        resetForm()
        return null
      }

      addError(WRONG_CODE)

      return null
    }

    setStorageValue(inputCode)

    resetForm()

    return null
  }

  const handleChange = (code: string) => {
    setInputCode(code)

    if (code.match(/[^0-9]/g)) {
      return addError(WRONG_FORMAT)
    }

    return removeError(WRONG_FORMAT)
  }

  return (
    <article className={styles.wrapper}>
      <div className={styles.headerWrapper}>
        <h1 className={styles.header}>Så fungerar barnlåset</h1>
      </div>
      <div className={styles.contentWrapper}>
        <p className={styles.ingress}>
          Allt innehåll är inte lämpligt för de små – därför finns barnlåset!
          Vill du aktivera barnlåset skriver du in en fyrsiffrig PIN-kod i
          fältet ”Ange kod”. Koden sparas i din lokala webbläsare och om du
          glömmer koden eller vill ta bort barnlåset rensar du webbhistoriken.
        </p>
        <div className={styles.lockWrapper}>
          <h2 className={styles.lockWrapperHeader} data-testid="lock-status">
            Barnlåset är
            {isActive ? ' aktivt' : ' inaktivt'}
          </h2>
          {isActive ? (
            <p
              className={styles.lockWrapperText}
              data-testid="text-deactivate-lock"
            >
              Ange din PIN-kod för att stänga av barnlåset:
            </p>
          ) : (
            <>
              <p className={styles.lockWrapperText}>
                Aktivera barnlåset för att förhindra uppspelning av olämpligt
                innehåll.
              </p>
              <p
                className={styles.lockWrapperText}
                data-testid="text-activate-lock"
              >
                Välj en PIN-kod för att aktivera barnlåset:
              </p>
            </>
          )}

          <form onSubmit={handleSubmit}>
            <input
              aria-label="Ange kod"
              className={styles.input}
              data-testid="parental-lock-pin"
              id="parental-lock-code"
              maxLength={4}
              onChange={e => handleChange(e.target.value)}
              placeholder="Ange kod"
              size={4}
              type="password"
              value={inputCode}
            />

            <button
              className={styles.button}
              onClick={e => handleSubmit(e)}
              data-testid="parental-lock-toggle"
              data-trk-parental-lock={isActive ? 'deactivate' : 'activate'}
              type="submit"
            >
              {isActive ? 'Inaktivera' : 'Aktivera'}
            </button>
            {errors.map(errorText => (
              <p
                key={errorText}
                className={styles.errorMessage}
                data-testid="error-message"
                role="alert"
              >
                {errorText}
              </p>
            ))}
          </form>
        </div>
      </div>
    </article>
  )
}

export default ParentalLock
