import { GetServerSidePropsContext } from 'next'
import queryString from 'query-string'
import { windowIsAvailable } from './helpers'
import { SearchParams } from 'backend/services/v1/urApiService.types'

export const urlEncodeParams = (params: object) => {
  return queryString.stringify(params, { arrayFormat: 'bracket' })
}

export const searchParams = (
  defaultParams: SearchParams = {},
): SearchParams => {
  const query = windowIsAvailable()
    ? queryString.parse(window.location.search, {
        arrayFormat: 'bracket',
      })
    : null

  return { ...defaultParams, ...query }
}

export const removeEmptyKeys = (initialObject: object) => {
  return Object.entries(initialObject).reduce((acc, [key, value]) => {
    if (value || typeof value === 'boolean') {
      acc[key] = value
    }
    return acc
  }, {})
}

export const getOrigin = (ctx: GetServerSidePropsContext) => {
  const { host } = ctx.req.headers

  return `${host?.includes('urplay.se') ? 'https' : 'http'}://${host}`
}
